import React from 'react';
import { FeedCardLayout } from 'generated/graphql';

type ProjectsGridProps = {
  layout?: FeedCardLayout;
  className?: string;
};

export const ProjectsGrid: React.FC<ProjectsGridProps> = ({ layout, className, children }) => {
  return (
    <div
      data-layout={layout}
      className={`grid gap-[0.625rem] mediumLarge:gap-4 grid-cols-[repeat(2,_calc(50%_-_0.625rem/2))]
        breakMobile:grid-cols-[repeat(3,_calc(33.33%_-_(0.625rem_-_0.625rem_/_3)))]
        mediumLarge:grid-cols-[repeat(4,_calc(25%_-_0.75rem))] data-[layout=${FeedCardLayout.Large}]:'mediumLarge:grid-cols-[repeat(2,_calc(50%-1rem/2))] ${className}`}>
      {children}
    </div>
  );
};
