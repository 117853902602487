import React from 'react';

type RibbonProps = {
  color: 'purple' | 'red';
  text: string;
};

export const Ribbon = ({ text, color }: RibbonProps) => {
  return (
    <div
      className=" absolute w-[150px] h-[150px] overflow-hidden bottom-0 -right-[1px] pointer-events-none z-[1]"
      data-testid="featured-banner">
      <div
        data-color={color}
        className="uppercase w-[200px] p-2 font-semibold text-[12px] 
        tracking-[1px] text-center leading-3 
        -rotate-45 translate-x-[17px] translate-y-[99px]
        drop-shadow-[0_5px_5px_rgba(0,0,0,0.5)] [text-shadow:0_2px_2px_rgba(0,0,0,0.4)]
        bg-accents-purple-primary data-[color=red]:bg-primary-main">
        {text}
      </div>
    </div>
  );
};
