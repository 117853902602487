import { Button } from 'components/common/buttons/Button';
import { Spacer } from 'components/layouts/Spacer';
import { Heading4 } from 'components/typography';
import Link from 'next/link';

export const FeedHeading = ({ heading, viewMoreLink }: { heading: string; viewMoreLink?: string }) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <>
          <Heading4>{heading}</Heading4>
          {viewMoreLink && (
            <Link href={viewMoreLink} prefetch={false}>
              <Button text="View all" variety="text" size="medium" />
            </Link>
          )}
        </>
      </div>
      <Spacer height={12} />
    </>
  );
};
