import { ErrorMessage } from 'components/typography';
import { ProjectCard } from 'components/cards/ProjectCard';
import { RecommendationSort, SortBy, useRecommendationsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import { ProjectsGrid } from './ProjectsGrid';
import { ProjectFeedLoader } from './ProjectFeedLoader';
import { usePathname } from 'next/navigation';

export const ProjectFeedRecommendedModule = ({
  module,
  feedIndex,
}: {
  module: PageModuleTypeProp<'ProjectFeedRegular'>;
  feedIndex: number;
}) => {
  const dive = useDive();
  const pathname = usePathname() ?? '';

  const { loading, data, error } = useRecommendationsQuery({
    variables: {
      quantity: module.quantity,
      sort: module.sortBy === SortBy.Random ? RecommendationSort.Random : RecommendationSort.Score,
    },
  });

  const trackProjectCardClick = (projectId: string, destination: string, name) => {
    dive.track('userInteract', {
      page_id: destination,
      page_id_before: pathname,
      name,
      type: 'feed',
      id: projectId,
    });
  };

  if (loading) {
    return <ProjectFeedLoader quantity={module.quantity} layout={module.layout} />;
  }

  if (error) {
    return <ErrorMessage message={error?.message} />;
  }
  const projects = data?.recommendations.items ?? [];

  const slug = module.page?.slug;
  const viewMoreLink = slug ? `/feed/${slug}/${module.sortBy.toLowerCase()}` : undefined;

  if (projects.length > 0) {
    return (
      <>
        <FeedHeading heading={module.title} viewMoreLink={viewMoreLink} />
        <ProjectsGrid layout={module.layout}>
          {projects.map((project, index) => {
            const name = feedIndex + '_' + module.title + '_' + index;
            return (
              <ProjectCard
                project={project}
                key={project.id}
                playOnClick={module.directPlayOnClick}
                showAuthor={Boolean(module.showAuthor)}
                onAuthorClick={() => trackProjectCardClick(project.id, `/user/${project.author.username}`, name)}
                onProjectClick={() =>
                  trackProjectCardClick(
                    project.id,
                    module.directPlayOnClick ? ENGINE_TRACK_URL : `/world/${Base64.fromNumber(Number(project.id))}`,
                    name
                  )
                }
              />
            );
          })}
        </ProjectsGrid>
      </>
    );
  }

  return null;
};

export default ProjectFeedRecommendedModule;
