import { LoadingCard, CirclePlaceholder, TextPlaceholder } from 'components/common/SkeletonLoader';

export const ProjectCardLoader = ({ hideTitle, hideAuthor }: { hideTitle?: boolean; hideAuthor?: boolean }) => {
  return (
    <div className="flex flex-col" aria-label="loading worlds...">
      <LoadingCard height="100%" style={{ maxHeight: '170px' }} borderRadius={8} aspectRatio="210 / 130" />
      {!hideTitle && (
        <div className="flex gap-2 items-start mt-2">
          {!hideAuthor && <CirclePlaceholder size={40} />}
          <div className="flex w-[80%] h-full gap-1">
            <TextPlaceholder width="calc(90% - 20px)" />
          </div>
        </div>
      )}
    </div>
  );
};
