import { Completion, Play } from '@hiberworld/icons';
import { Link } from 'components/common/Link';
import { Ribbon } from 'components/common/Ribbon';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { Spacer } from 'components/layouts/Spacer';
import { Label } from 'components/typography';
import { GameKit } from 'generated/graphql';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { ReactElement } from 'react';
import Base64 from 'utils/Base64';
import { formatNumber } from 'utils/formatNumber';
import { formatPercentage } from 'utils/formatPercentage';
import { ProjectCardProps } from './ProjectCard.types';

const NothingOrLink = ({
  children,
  title,
  href,
  noLink,
}: {
  children: ReactElement;
  title: string;
  href: string;
  noLink?: boolean;
}) => {
  if (noLink) {
    return children;
  }

  return (
    <Link href={href} passHref aria-label={`Visit ${title}`} prefetch={false}>
      {children}
    </Link>
  );
};

export const ProjectCard = ({
  project,
  trackEvent,
  showAuthor,
  dataCy,
  playOnClick,
  onAuthorClick,
  onProjectClick,
  hideTitle,
  hideStats,
}: ProjectCardProps) => {
  const { id, title, thumbnail, author, featured, totalViews, completionRate, gameKit } = project;
  const session = useGetOrCreateDaoSession();

  const joinSession = () => {
    session.loadAndJoinSession(project.id);
  };

  if (author.id === '-1') {
    // If the project author is invalid (deleted or blocked), don't show the card
    return null;
  }

  return (
    <div
      className="mb-2 relative"
      data-testid="project-card"
      data-cy={dataCy}
      onClick={() => {
        trackEvent?.();
      }}>
      <NothingOrLink href={`/world/${Base64.fromNumber(id)}`} title={title} noLink={playOnClick}>
        <div
          className="relative flex flex-1 rounded-lg overflow-hidden aspect-video transition-transform"
          onClick={onProjectClick}>
          <img
            className="object-cover bg-no-repeat w-full transition-transform duration-300 will-change-transform hover:scale-110"
            src={thumbnail?.medium?.url ?? ''}
            alt="Click to see world"
          />
          {featured && <Ribbon text="featured" color="purple" />}
          {playOnClick && (
            <div
              className="w-full h-full absolute justify-center items-center flex cursor-pointer group"
              onClick={joinSession}>
              <button
                data-testid="play-button"
                className="cursor-pointer rounded-full p-1 border-none bg-greyscale-hiberBlack/30 group-hover:bg-primary-main duration-200 transition-all group-hover:pr-12 medium:group-hover:pr-14">
                <Play className="inline-block text-greyscale-white w-8 h-8 medium:w-10 medium:h-10 align-middle "></Play>
                <span className="absolute top-[50%] translate-y-[-50%] opacity-0 font-semibold text-p_medium transition-all duration-200 group-hover:opacity-100 align-middle pr-4 translate-x-[-2rem] group-hover:translate-x-0">
                  Join
                </span>
              </button>
            </div>
          )}
          {!hideStats && (
            <Label size="specialSmall">
              <div className="flex items-center text-label_specialSmall leading-none font-bold bg-greyscale-hiberBlack/30 rounded-md p-1 pr-2 text-greyscale-white absolute bottom-2 left-2 pointer-events-none">
                <Play />
                {formatNumber(totalViews)}
                {gameKit === GameKit.Platformer && (
                  <>
                    <Spacer width={8} />
                    <Completion />
                    {formatPercentage(completionRate)}
                  </>
                )}
              </div>
            </Label>
          )}
        </div>
      </NothingOrLink>
      <div className="mt-2 max-w-full">
        {showAuthor && (
          <div className="inline-block w-[40px] align-top">
            <Link href={`/user/${author.username}`} passHref legacyBehavior prefetch={false}>
              <img
                className="rounded-full cursor-pointer"
                onClick={onAuthorClick}
                src={author.pictureUrl}
                alt="Visit creators profile"
                height={40}
                width={40}
              />
            </Link>
          </div>
        )}
        {!hideTitle && (
          <div className={`inline-block align-top pl-2 ${showAuthor ? 'w-[calc(100%-40px)]' : 'w-full'}`}>
            {!showAuthor && (
              <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                <Link href={`/world/${Base64.fromNumber(id)}`} passHref prefetch={false}>
                  <label
                    className="text-white font-medium text-label_large cursor-pointer hover:text-greyscale-lightGrey transition-colors"
                    data-testid="project-card-title"
                    onClick={onProjectClick}>
                    {title}
                  </label>
                </Link>
              </div>
            )}
            {showAuthor && (
              <div className="" onClick={onAuthorClick}>
                <Link href={`/world/${Base64.fromNumber(id)}`} passHref prefetch={false}>
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                    <label
                      className="text-white font-medium text-label_large cursor-pointer hover:text-greyscale-mediumGrey transition-colors"
                      data-testid="project-card-title"
                      onClick={onProjectClick}>
                      {title}
                    </label>
                  </div>
                </Link>
                <div className="flex gap-1">
                  <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                    <Link href={`/user/${author.username}`} prefetch={false}>
                      <label className="text-label_medium text-greyscale-lightGrey cursor-pointer transition-colors hover:text-greyscale-mediumGrey">
                        {author.username}
                      </label>
                    </Link>
                  </div>
                  <div className="flex *:p-0">
                    <VerifiedBadge isEmployee={author.isEmployee} size={20} />
                    <VerifiedBadge isVerified={author.isVerified} size={20} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
